@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

$font-family: 'Roboto', sans-serif;

@mixin btn($fontcolor: #FAFAFA){
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  outline: 0;
  border: 0;
  width: 100%;
  padding: 15px;
  background: #FF5722;
  color: $fontcolor;
  text-transform: uppercase;
}

@mixin message($fontcolor: #90A4AE){
  color: $fontcolor;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}

@mixin input($bkgnd: #F5F5F5){
  font-family: $font-family;
  width: 100%;
  border: 0;
  margin: 0 0 15px;
  padding: 15px;
  outline: 0;
  font-size: 15px;
  text-align: center;
  box-sizing: border-box;
  background: $bkgnd;
}

body{
  font-family: $font-family;
}


.container{
  padding: 8% 0 0;
  // width: 365px;
  margin: auto;
}

.form{
  position: relative;
  z-index: 1;
  max-width: 350px;
  margin: 0 auto 100px;
  padding: 45px;
  background: #FFFFFF;
}

.form {
  input{
    @include input; 
    
    ::placeholder {
      color: #90A4AE;
    }
  }
  
  .btn{
    @include btn;
  }
  
  .message {
    @include message;
    a {
      text-decoration: none;
      color: #00BFA5;
    }
  }
}

.form .register-form{
  display: none;
}

