.list {
    display: flex;
    width: 100%;
    .listContainer {
      flex: 6;
      margin-top: 10px;
    }
    .listTitle {
        font-weight: 500;
        color: gray;
        margin-bottom: 15px;
      }
  }
  