.table {
  .cellWrapper {
    display: flex;
    align-items: center;

    .image {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      margin-right: 10px;
      object-fit: cover;
    }
  }

  .status {
    padding: 5px;
    border-radius: 5px;

    &.Approved {
      color: green;
      background-color: rgba(0, 128, 0, 0.151);
    }
    &.Pending {
      color: goldenrod;
      background-color: rgba(189, 189, 3, 0.103);
    }
  }
  
}
.pagination-button {
  margin: 5px;
  padding: 5px 10px;
  border: none;
  background-color: transparent;
  color: black;
  cursor: pointer;
}

.pagination-button.active {
  background-color: #e0e0e0;
}
